import type { GatsbyBrowser } from 'gatsby';
import { getCookie } from '@lucid/utils';

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ prevLocation }) => {
  let { pathname } = window.location;

  if (pathname === '/' || pathname === 'pages/') {
    pathname = 'homepage';
  }

  if (prevLocation && !(prevLocation as Location).pathname.includes('/contact/')) {
    // set session storage so we can use it on single page marketo form
    try {
      sessionStorage.setItem('previousPageviewId', getCookie('lt-pageview-id') || '');
      sessionStorage.setItem('prevLocation', (prevLocation as Location).pathname);
    } catch {
      // no-op
    }
  }
};

export { onRouteUpdate };
